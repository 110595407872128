import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';

interface HolidayEvent {
  uuid: string;
  name: string;
  date: string;
  status: string;
}

const initialState = {
  events: [] as HolidayEvent[],
  error: '',
  isLoading: false,
  locations: [],
};

const holidaySlice = createSlice({
  name: 'holidays',
  initialState,
  reducers: {
    setHolidays: (state, action) => {
    state.events = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    addHoliday: (state, action) => {
        state.events.push(action.payload);
      },
    removeHolidayByDate: (state, action) => {
        state.events = state.events.filter(event => event.date !== action.payload.date);
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
  },
    extraReducers: (builder) => {
      builder.addCase(uploadHolidayCalendar.fulfilled, (state, action) => {
        state.events = action.payload.response.csv_data; // Assuming CSV data is returned
      });
  },
});

export const { setHolidays, setError, setLoading, removeHolidayByDate, setLocationList } = holidaySlice.actions;

const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  dispatch(setError(''));
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error: any) {
    dispatch(setLoading(false));
    if (error?.response?.data) {
      dispatch(setError(error.response.data));
    } else if (error?.response?.status) {
      dispatch(setError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setError(errorMessages));
    }
    throw error;
  }
};

export const fetchHolidays = createAsyncThunk(
  'holidays/fetchHolidays',
  async (_, { dispatch }) => {
    const request = () => axios.get(Api.calenders);
    const response = await handleAsyncThunk(dispatch, request, setHolidays);
    return { success: true, response: response?.data };
  }
);

export const addHoliday = createAsyncThunk(
  'holidays/addHoliday',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.calenders + Api.add, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const updateHoliday = createAsyncThunk(
  'holidays/updateHoliday',
  async (data: any, { dispatch }) => {
    const request = () => axios.post(Api.calenders + Api.update, data);
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const deleteHoliday = createAsyncThunk(
  'holidays/deleteCalendar',
  async (uuid: string, { dispatch }) => {
    const request = () => axios.post(Api.calenders + Api.delete, { uuid });
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const addSingleHoliday = createAsyncThunk(
    'holidays/addSingleHoliday',
    async (data: { calendar_uuid: string; date: string; title: string }, { dispatch }) => {
      const request = () => axios.post(Api.calenders + Api.add_single_holiday, data);
      const response = await handleAsyncThunk(dispatch, request);
      return { success: true, response: response?.data };
    }
  );
  
export const deleteSingleHoliday = createAsyncThunk(
  'holidays/deleteSingleHoliday',
  async (data: { calendar_uuid: string; date: string }, { dispatch }) => {
      const request = () => axios.post(Api.calenders + Api.delete_holiday, data);
      const response = await handleAsyncThunk(dispatch, request);
      return { success: true, response: response?.data };
  }
);
export const uploadHolidayCalendar = createAsyncThunk(
  'holidays/uploadHolidayCalendar',
  async (formData: FormData, { dispatch }) => {
    const request = () => axios.post(`${Api.calenders}${Api.bulkUpload}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    const response = await handleAsyncThunk(dispatch, request);
    return { success: true, response: response?.data };
  }
);

export const fetchLocationList = createAsyncThunk(
  'holidays/fetchLocationList',
  async (_, { dispatch }) => {
    const request = () => axios.get(Api.list_all_locations)
    await handleAsyncThunk(dispatch, request, setLocationList);
  }
);

export default holidaySlice.reducer;