import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { DailyStatusState } from '../model/status.model';
// config
import { PAGINATION_LINK } from '../config-global';

const initialState: DailyStatusState = {
  isLoading: false,
  statusData: [],
  statusDetails: {},
  locations:[],
  teams: [],
  error: {},
  totalData: 0
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setStatusList(state, action) {
      const { daily_status, count } = action.payload;
      state.statusData = daily_status;
      state.totalData = count;
    },
    setStatusDetails(state, action) {
      const { statusDetail } = action.payload;
      state.statusDetails = statusDetail;
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
    setStatusError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setLoading,
  setLocationList,
  setStatusList,
  setStatusError,
  setStatusDetails
} = statusSlice.actions;

export default statusSlice.reducer;
const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setStatusError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setStatusError(errorMessages));
    }
    throw error;
  }
};

export const fetchDailyStatus = createAsyncThunk(
  'status/fetchDailyStatus',
  async (
    {
      page = 0,
      limit = null,
      traineeUuid = '',
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId='',
    }: {  
        page?: number;
        limit?: number | null;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        traineeUuid?: string;
        startDate?: any;
        endDate?: any
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: limit ? `${Number(page) * Number(limit)}` : '',
      limit: limit || '',
      sort,
      sort_key: sortKey,
      filter_name_uuid: traineeUuid,
      start_date: startDate,
      end_date: endDate,
      filter_team_uuid:teamId,
    };

    const request = () => axios.get(Api.status, { params });
    await handleAsyncThunk(dispatch, request, setStatusList);

  }
);

export const deleteStatus = createAsyncThunk('status/deleteStatus', async (data: any, { dispatch }) => {
  try {
    await axios.post(Api.deleteStatus, data);
    return { success: true }; // Return success flag
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setStatusError(error.message));
    } else {
      // Specific error message handled
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setStatusError(errorMessages));
    }
    throw error; // Throw the error to indicate failure
  }
});

export const addStatus = createAsyncThunk('status/addStatus', async (data: any, { dispatch }) => {
  try {
    const response = await axios.post(Api.addStatus, data);
    return response.data;
  } catch (error) {
    if (error?.data?.length === 0) {
      // Custom message handled
      dispatch(setStatusError(error?.message));
    } else if (error?.response?.data?.length !== 0) {
      // Specific error message handled
      const errorMessages = Object.values(error.response.data.errors).flat();
      dispatch(setStatusError(errorMessages));
      return {status: "error", message: errorMessages};
    }
    throw error; // Throw the error to indicate failure
  }
});


