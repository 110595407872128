import { AnyAction, createAsyncThunk, createSlice, Dispatch } from '@reduxjs/toolkit';
// components
import en from '../locales/langs/en';
import { setSession, setUserData, setUserPermission, setLastLoggedInUser } from '../auth/utils';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { fetchUserProfile } from './userSlice';

const initialState = {
  loading: false,
  user: {},
  isAuthenticated: false,
  error: {},
  accessToken: null,
  profilePicture: '',
};

const auth = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    authStart(state) {
      state.loading = true;
    },
    authFinished(state) {
      state.loading = false;
    },
    authSuccess(state, action) {
      const accessToken = action.payload.access_token;
      const { name, email, profile_picture } = action.payload.user;
      state.user = action.payload.user;
      state.isAuthenticated = true;
      state.loading = false;
      state.accessToken = accessToken;
      setSession(accessToken);
      setUserData(action.payload.user);
      setUserPermission(action.payload.user_permissions)
      setLastLoggedInUser({ name, email, profile_picture });
    },
    authFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getLogoutSuccess(state) {
      state.user = {};
      state.accessToken = null;
      state.isAuthenticated = false;
      setSession(null);
    },
  },
});

export const { authStart, authSuccess, authFail, getLogoutSuccess, authFinished } = auth.actions;
export default auth.reducer;

export const signIn = createAsyncThunk('auth/signIn', async (token: any, { dispatch }) => {
  try {
    dispatch(authStart());
    const response = await axios.post(Api.login, { token });
    dispatch(authSuccess(response?.data?.data));
    dispatch(fetchUserProfile());
    return { success: true }; // Return success flag
  } catch (error) {
    dispatch(authFail(error?.response?.data?.message));
    throw error; // Throw the error to indicate failure
  }
});

export const logout = () => async (dispatch: Dispatch<AnyAction>) => {
  try {
    await axios.post(Api.logout);
    dispatch(getLogoutSuccess());
    return { success: true };
  } catch (error) {
    console.error(en.logout_error, error);
    throw error;
  }
};
