import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { TraineesState } from '../model/tasks.model';
// config
import { PAGINATION_LINK } from '../config-global';

const initialState: TraineesState = {
  isLoading: false,
  locations:[],
  error: {},
  totalData: 0,
  traineesData:[],
  count:0,
};

export const traineesSlice = createSlice({
  name: 'trainees',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTraineesList(state, action) {
      const { trainees, total_count,count } = action.payload;
      state.traineesData = trainees;
      state.totalData = total_count;
      state.count = count;
    },
    setLocationList(state, action) {
      state.locations = action.payload
    },
    setTasksError(state, action) {
      state.error = action.payload;
    },
  },
});
export const {
  setLoading,
  setLocationList,
  setTraineesList,
  setTasksError
} = traineesSlice.actions;

export default traineesSlice.reducer;
const handleAsyncThunk = async (dispatch: any, request: any, thunkAction?: any) => {
  try {
    dispatch(setLoading(true));
    const response = await request();
    if (thunkAction) dispatch(thunkAction(response.data.data));
    dispatch(setLoading(false));
    return response;
  } catch (error) {
    dispatch(setLoading(false));
    if (error?.response?.status) {
      dispatch(setTasksError(error.message));
    } else {
      const errorMessages = Object.values(error?.data).flat();
      dispatch(setTasksError(errorMessages));
    }
    throw error;
  }
};

export const fetchTraineeList = createAsyncThunk( 
  'trainee/fetchTraineeList',
  async (
    {
      page = 0,
      limit = null,
      searchTerm = '',
      sort = '',
      sortKey = '',
      startDate = '',
      endDate = '',
      teamId = '',
      taskReview = 'false'
    }: {  
        page?: number;
        limit?: number | null;
        employeeId?: string;
        searchTerm?: string;
        teamId?: string;
        sort?: string;
        sortKey?: string;
        type?: string;
        startDate?: any;
        endDate?: any;
        taskReview?:string;
      },
    { dispatch }
  ) => {

    const params = {
      search: searchTerm,
      skip: limit ? `${Number(page) * Number(limit)}` : '',
      limit: limit || '',
      sort,
      sort_key: sortKey,
      start_date: startDate,
      end_date: endDate,
      team_uuid:teamId,
      task_review:taskReview
    };

    const request = () => axios.get(Api.trainees, { params });
    await handleAsyncThunk(dispatch, request, setTraineesList);

  }
);

