const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hi`,
    description: `Need help? \n Please check our docs.`,
    documentation: `documentation`,
  },
  action_buttons: {
    view: 'View',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    confirm: 'Confirm',
    are_you_sure_to_delete: "Are you sure want to delete"
  },
  dashboard: 'Dashboard',
  dailystatus: 'Daily Status',
  cancelSession: 'Cancel Session',
  login_failed : 'Login Failed. Please try again with qburst email',
  review: 'Review',
  reviews: 'Reviews',
  review_type: 'Review Type',
  assignedTasks: 'Assigned Tasks',
  assignedTask: 'Task',
  statuschange: 'Change task status',
  comment: 'Comments',
  Reason: 'Reason',
  title: 'Title',
  hours_worked: 'Hours worked',
  description_help: "Describe the work you have done.",
  task:{
    title: 'Task Title',
    description: 'Description',
    assigned_count:"Assigned Members",
    type: "Type"
  },
  trainee: "Trainee",
  mentor: "Mentor",
  is_trainee: "Is this employee a trainee?",
  is_mentor: "Is this employee a mentor?",
  optional: 'Optional',
  clear: 'Clear',
  profile: 'Profile',
  project: 'Project',
  create: 'Create',
  create_a_new: 'Create a New',
  edit: 'Edit',
  skill: 'Skill',
  failed: 'Something went wrong!',
  add_failed: 'Failed to save the changes',
  update_success: 'Update success!',
  update_failed: 'Update failed!',
  delete_success: 'Deleted successfully!',
  upload_success: 'Upload success!',
  upload_failed: 'Upload failed!',
  delete_failed: 'Failed to delete!',
  save: 'Save Changes',
  save_continue: 'Save & continue',
  experience: 'Experience',
  relevant_experience: `Relevant Experience`,
  start_date: 'Start Date',
  end_date: 'End Date',
  due_date: 'Due Date',
  half_day: 'half-day',
  full_day: 'full-day',
  day: 'Day',
  days: 'Days',
  total: 'Total',
  allocation: 'Allocation',
  allocation_status: 'Allocation Status',
  name: 'Name',
  add: 'Add',
  mobile: 'Mobile',
  whatsapp: 'WhatsApp Number',
  date_of_joining: 'Date of Joining',
  stream: 'Stream',
  years: 'years',
  months: 'months',
  email: 'Email',
  createdBy: 'Created By',
  createdBefore: 'Created Before',
  createdAfter: 'Created After',
  assigneeCount: 'Assignee Count',
  empty_skill_set: 'The skill set is empty.',
  skills: 'Skills',
  update: 'Update',
  contact: 'Contact',
  employees: 'Employees',
  employee: 'Employee',
  employee_id: 'Employee ID',
  employee_list: 'Employees',
  wfh:'wfh',
  team: 'Team',
  teams: 'Teams',
  task_heading: 'Task',
  tasks: 'Tasks',
  training_tasks: 'Training Tasks',
  team_id: 'Team ID',
  team_list: 'Teams',
  teamDetailsView: 'Team Details',
  members: 'Members',
  member_count: 'Member Count',
  new: 'New',
  app_name: 'VT Management',
  actions: 'Actions',
  required_error: 'This is required',
  name_required_error: 'Name is required',
  email_required_error: 'Email is required',
  invalid_email_error: 'Not a valid email address',
  mobile_required_error: 'Mobile is required',
  stream_required_error: 'Stream is required',
  number_type_error: 'Not a valid number',
  phone_number_type_error: 'Not a valid phone number',
  alphanumeric_type_error: 'Only alpha numeric characters allowed',
  joined_date_required_error: 'Joined date is required',
  Name: 'Name',
  role: 'Role',
  roles: 'Roles',
  role_list: 'Roles',
  permissions: 'Permissions',
  permission_list: 'Permissions',
  session: 'Session',
  sessions: 'Sessions',
  sessions_list: 'Sessions List',
  session_type: 'Session Type',
  session_attendance_count: 'Attendance Count',
  session_status: {
    upcoming: 'Upcoming',
    completed: 'Completed',
    ongoing: 'Ongoing',
    cancelled: 'Cancelled',
    total: 'Total',
  },
  participation_type: 'Participation Type',
  sub_role: 'Sub Role',
  session_by: 'Session By',
  session_organisers: 'Session Organisers',
  session_attendance_status: 'Attendance Status',
  my_attendance: 'My Calender',
  holiday_calender: 'Holiday Calender',
  all_employee_attendance: 'All Employees',
  date_time: 'Date & Time',
  date: 'Date',
  last_updated: 'Last Updated',
  comments: 'Comments',
  attachment: 'Attachment',
  attachment_file: 'Attachment Link',
  empty_attachment: 'No attachments uploaded.',
  vt_team: 'Virtual Team',
  project_type: 'Project Type',
  add_another: 'Add Another',
  add_skill: 'Add Skill',

  interview: 'Interview',
  interviews: 'Interviews',
  interview_list: 'Interviews',
  time: 'Time',
  interviewer: 'Interviewer',
  interview_lead: 'Lead Interviewer',
  other_interviewers: 'Other Interviewers',
  technology: 'Technology',
  round: 'Round',
  candidate: 'Candidate',
  candidate_exp: 'Experience',
  interview_status: 'Status',
  interview_date: 'Interview created Date',
  feedback: 'Feedback',
  lead_interviewer: 'Lead Interviewers',
  other_interviewer: 'Other Interviewers',
  time_slot: 'Time Slot',
  rating: 'Rating',
  attendance: 'Session Attendance',
  logout_error: 'Error occurred while logging out:',
  attended: 'Attended',
  career: 'Career',
  company: 'Company',
  location: 'Location',
  locations: 'Locations',
  tech_knowledge: 'Technical/Job Knowledge',
  attitude: 'Attitude Rating',
  communication: 'Communication',
  logical_reasoning: 'Logical Reasoning',
  designation: 'Designation',
  department: 'Department',
  interview_details: 'Interview Details',
  interview_date_time: 'Date & Time',
  addRound: 'addRound',
  editRound: 'editRound',
  edit_a_round: 'Edit Round',
  add_new_round: 'Add New Round',
  interview_type: 'Interview Type',
  interview_location: 'Interview Location',
  employee_attendance: 'Attendance',
  qb_experience: 'Qburst Experience',
  interview_job_code: 'Job Code',

  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',

  interview_duration: 'Interview Duration',
  download_resume: 'Download Resume',
  employeeDetailsView: 'Employee Details',
  present: 'present',
  absent: 'absent',
  leave: 'leave',
  final_rating: 'Final Rating',
  final_comments: 'Final Comments',
  completed: 'Completed',
  not_completed: 'Not Completed',
  view: 'View',
  delete: 'Delete',

  status:'Status',
  jobCode: 'Job Code',
  jobTitle: 'Job Title',
  jobLocation: 'Job Location',
  jobDescription: 'Job Description',
  interviewTime: 'Interview Time',
  reportingTime: 'Reporting Time',
  timeZone: 'Time Zone',
  finalRound: 'Final Round',
  isSelected: 'Candidate Selected',
  interview_rounds:'Interview Rounds',

  taskDetails: 'Description',
  assignees: 'Assignees',
  attachments: 'Attachments',
  activityLog: 'Activity Log',
  attandance:{
    wfo: 'Work From Office',
    wfh: 'Work From Home',
    onLeave: 'On Leave',
    fnLeave: 'FN Leave',
    anLeave: 'AN Leave',
    totalLeave: 'Total Leave',
  },
  default:'default'
};

export default en;
