import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../utils/axios';
import Api from '../utils/apiUrls';
import { ICalendarState } from '../@types/calendar';

const initialState: ICalendarState = {
  isLoading: false,
  isEventFetchLoading: false,
  events: [],
  error: null,
  userFilterList:[],
};

const handleAsyncThunk = async (dispatch: any, request: any, action?: any) => {
    try {
      if (action === setCalendarEvents) {
        dispatch(setEventFetchLoading(true));
      }
      const response = await request();
      if (action) dispatch(action(response.data));
    } catch (error) {
      dispatch(setCalendarError(error.message || 'Error occurred'));
      throw error;
    } finally {
      if (action === setCalendarEvents) {
        dispatch(setEventFetchLoading(false));
      }
    }
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setEventFetchLoading(state, action) {
      state.isEventFetchLoading = action.payload;
    },
    setCalendarEvents(state, action) {
      state.events = action.payload;
    },
    modifyCalendarEvent(state, action) {
      state.events = action.payload;
    },
    setCalendarError(state, action) {
      state.error = action.payload;
    },
    setUserFilterList(state, action){
      state.userFilterList = action.payload.data;
    },
    setSyncData(state, action) {
    },
  },
});

export const {
  setLoading,
  setCalendarEvents,
  modifyCalendarEvent,
  setCalendarError,
  setUserFilterList,
  setSyncData,
  setEventFetchLoading,
} = calendarSlice.actions;

export default calendarSlice.reducer;

export const fetchAttendanceStatus = createAsyncThunk(
  'attendance/status',
  async (
    {
      userId = '',
      fromDate = '',
      toDate = '',
    }: { userId?: string; fromDate?: string; toDate?: string },
    { dispatch }
  ) => {
    const params = {
      user_uuid: [userId],
      from_date: fromDate,
      to_date: toDate,
    };
    const request = () => axios.get(Api.attendanceStatus, { params });
    await handleAsyncThunk(dispatch, request, setCalendarEvents);
  }
);

export const addOrUpdateAttendanceStatus = createAsyncThunk(
  'attendance/update-attendance',
  async (
    {
      userId = '',
      date = '',
      status = '',
      start = null,
      end = null,
      targetEventType = null,
      removeLeave = false,
      remarks = '',
    }: {
        userId?: string;
        date?: string;
        status?: string;
        start: string|Date|null;
        end: string|Date|null;
        targetEventType: string | null;
        removeLeave: boolean;
        remarks: string;
       },
    { dispatch }
  ) => {
    const params = {
      user_uuid: userId,
      date,
      status,
      start_date: start,
      end_date: end,
      target_event_type: targetEventType,
      remove_leave: removeLeave,
      remarks,
    };
    const request = () => axios.post(Api.adOrUpdateAttendance, { params });
    await handleAsyncThunk(dispatch, request, modifyCalendarEvent);
  }
);

export const getUserFilterList = createAsyncThunk('user/getUserFilterList', async (_, { dispatch }) => {
  const request = () => axios.get(Api.userFilterList);
  await handleAsyncThunk(dispatch, request, setUserFilterList);
});

export const syncAttendance = createAsyncThunk(
  'attendance/sync',
  async (_, { rejectWithValue }) => {
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];

    const params = {
      from_date: formattedDate,
      to_date: formattedDate,
    };
    try {
      const response = await axios.post(Api.attendanceSync, params);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || { message: 'Syncing Failed' });
    }
  }
);
