// routes
import { checkPermissions } from 'src/pages/common/util';
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  calendar: icon('ic_calendar'),
  permissions: icon('ic_lock'),
  roles: icon('ic_chat'),
  training: icon('ic_task'),
  holidayCalendar: icon('ic_booking'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.home, icon: ICONS.dashboard, permission: 'view', permission_label: 'dashboard' },
      { title: 'Teams', path: PATH_DASHBOARD.teams, icon: ICONS.ecommerce, permission: 'list' ,permission_label: 'teams' },
      { title: 'Employees', path: PATH_DASHBOARD.employees, icon: ICONS.user, permission: 'employee-list',permission_label: 'employees'  },
      { title: 'Sessions', path: PATH_DASHBOARD.sessions, icon: ICONS.analytics, permission: 'list',permission_label: 'session'  },
      { title: 'Interviews', path: PATH_DASHBOARD.interviews, icon: ICONS.roles, permission: 'list' ,permission_label: 'interviews' },
      { title: 'Attendance', path: PATH_DASHBOARD.attendance, icon: ICONS.calendar, permission: 'list',permission_label: 'attendance'  },
      {
        title: 'Training',
        icon: ICONS.training,
        path: PATH_DASHBOARD.training, // Placeholder path
        permission: 'list',
        permission_label: 'training_module',
        children: [
          { title: 'Tasks', path: PATH_DASHBOARD.listTasks, permission: 'list', permission_label: 'task' },
          // { title: 'Add tasks', path: PATH_DASHBOARD.addTask, permission: 'add', permission_label: 'task' },
          { title: 'Trainees', path: PATH_DASHBOARD.traineeList, permission: 'list', permission_label: 'trainees' },
          { title: 'Assigned Tasks', path: PATH_DASHBOARD.assignedTasks, permission: 'list', permission_label: 'assigned_task' },
          { title: 'Daily Status', path: PATH_DASHBOARD.dailyStatus, permission: 'list', permission_label: 'dailystatus' },
        ],
      },
      {title: 'Reviews', path: PATH_DASHBOARD.review, icon: ICONS.calendar, permission: 'list',permission_label: 'review'},
      {title: 'Holiday Calendar', path: PATH_DASHBOARD.holidayCalendar, icon: ICONS.holidayCalendar, permission: 'list',permission_label: 'holidayCalendar'},
    ],
  },
];

export default navConfig;
